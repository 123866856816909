import React, { Component } from "react"
import p5 from 'p5/lib/p5.min'


// https://github.com/bmoren/p5.collide2D/blob/master/p5.collide2d.js
const collideRectRect = (x, y, w, h, x2, y2, w2, h2) => {
	//2d
	//add in a thing to detect rectMode CENTER
	if (x + w >= x2 &&    // r1 right edge past r2 left
		x <= x2 + w2 &&    // r1 left edge past r2 right
		y + h >= y2 &&    // r1 top edge past r2 bottom
		y <= y2 + h2) {    // r1 bottom edge past r2 top
		return true;
	}
	return false;
};

const collideCircleCircle = (sketch, x, y, d, x2, y2, d2) => {
	//2d
	if (sketch.dist(x, y, x2, y2) <= (d / 2) + (d2 / 2)) {
		return true;
	}
	return false;
};

const randomIntFromInterval = (min, max) => { // min and max included
	return Math.floor(Math.random() * (max - min + 1) + min)
}


class CatObstacle {
	constructor(sketch, board_width, board_height, img_path) {
		this.sketch = sketch;

		this.size = 143 / 2;

		this.x = board_width + this.size;
		this.y = board_height - this.size;

		let r = Math.random();
		this.vx = randomIntFromInterval(2,7);

		this.img = this.sketch.loadImage(img_path)
	}

	move() {
		this.x -= this.vx;
	}

	show () {
		this.sketch.image(this.img, this.x, this.y, this.size, this.size);
		// this.sketch.fill(255, 50);
		// this.sketch.ellipseMode(this.sketch.CORNER);
		// this.sketch.ellipse(this.x, this.y, this.size);
	}

	readyToRemove() {
		return (this.x < -this.size*2 )
	}
}


class Cat {
	sketch;

	size;
	y_lower_bound;
	x_start_position;

	gravity;
	img;

	constructor(sketch, board_height, x_start_position, img_path, img_fight) {
		this.size = 143 / 2;

		this.x_start_position = x_start_position;
		this.y_lower_bound = board_height - this.size;

		this.x = this.x_start_position;
		this.y = this.y_lower_bound;
		this.sketch = sketch;
		this.vy = 0;
		this.gravity = 2;
		this.img = this.sketch.loadImage(img_path)
		this.imgFight = this.sketch.loadImage(img_fight);
	}

	jump() {
		// if (this.y == this.y_lower_bound) {
		if (this.y > 0) {
			this.vy = -35;
		}
	}

	move() {
		this.y += this.vy;
		this.vy += this.gravity;
		this.y = this.constrain(this.y, 0, this.y_lower_bound)
	}
	constrain(val, lower, upper) {
		if (val < lower) return lower;
		if (val > upper) return upper;
		return val;
	}

	hits(obstacle) {
		return (collideCircleCircle(this.sketch, this.x, this.y, this.size,
  			obstacle.x, obstacle.y, obstacle.size))
	}

	show(isFight=false) {
		this.sketch.image(this.img, this.x, this.y, this.size, this.size);
		if (isFight) {
			this.sketch.image(this.imgFight, this.x, this.y, this.size, this.size);
		}
		// this.sketch.fill(255, 50);
		// this.sketch.ellipseMode(this.sketch.CORNER);
		// this.sketch.ellipse(this.x, this.y, this.size);
	}
}

class GameState {
	isRunning;
	isFight;
	currentFights;
	currentJumps;
	maxFights;

	constructor() {
		this.isRunning = true;
		this.isFight = false;

		this.currentFights = 0;
		this.currentJumps = 0;
		this.maxFights = 5;
	}

	restart() {
		this.currentFights = 0;
		this.currentJumps = 0;
		this.isRunning = true;
		this.isFight = false;
	}

	startFight() {
		this.isFight = true;
		setTimeout(() => { this.isFight = false }, 1000)
		this.currentFights++;
	}

	end() {
		this.isRunning = false;
	}

	isEndGame() {
		return (this.isPogoWinner() || this.isSheraWinner());
	}

	getScoreMessages () {
		return [
			`Shera: ${this.currentJumps}`
			, `Pogo: ${this.currentFights}`
		];
	}
	getWinnerMessage () {
		if (this.isSheraWinner()) {
			return `Shera Wins!`
		}
		return `Pogo Wins!`
	}
	isPogoWinner () {
		return (this.currentFights >= this.maxFights);
	}
	isSheraWinner() {
		return (this.currentJumps >= this.maxFights);
	}

}

/*
* GameSketch manages the visual drawing aspects of the game using Sketch and P5.js
*/
class GameSketch {
	sketch;
	options;

	shera;
	pogo;
	obstacles = [];
	gameState;

	constructor(sketch, options) {
		this.gameState = new GameState();

		this.styleOptions = {
			bar_bgcolor: "purple",
			bar_txtcolor: "white",
			line_color: 'purple',
			line_weight: 1,

			main_color: 'black',
			main_padding: 20,
		}
		this.sketch = sketch;
		this.options = options;

		this.shera = new Cat(this.sketch, this.options.board_height, 20, "/shera-avatar.png", "/smoke.png");


		this.winnerShera = new Cat(this.sketch, this.options.board_height, 20, "/shera-avatar.png", "/smoke.png");
		this.winnerPogo = new Cat(this.sketch, this.options.board_height, this.options.board_width-this.shera.size-20, "/pogo-avatar.png");


		this.sketch.setup = this.setup.bind(this);
		this.sketch.draw = this.draw.bind(this);
		this.sketch.mousePressed = this.mousePressed.bind(this);
		this.sketch.keyPressed = this.keyPressed.bind(this);

		this.divBtn = this.sketch.createDiv('').size(this.options.board_width, this.options.score_height*2);
		// this.divBtn.parent(this.canvas)

		this.btnRestart = this.sketch.createButton('Restart');
		this.btnRestart.center();
		this.btnRestart.hide();
		this.btnRestart.mousePressed(this.restartGame.bind(this));
		this.btnRestart.parent(this.divBtn)

		this.obstacles_chances = 0.005;
		this.obstacles_max = 3;
	}

	restartGame() {
		this.gameState.restart();

		this.btnRestart.hide();
		this.obstacles = [];
		this.sketch.loop();

		if (this.winner) {
			this.winner.hide();
		}
	}

	catFight(obj) {
		this.gameState.startFight()
		this.obstacles = this.obstacles.filter(o=>o!=obj);
	}

	endGame() {
		this.gameState.end();
		this.btnRestart.show();
		this.sketch.clear();
		this.draw();
	}

	setup () {
		this.canvas = this.sketch.createCanvas(this.options.board_width, this.options.board_height /* + this.options.score_height + this.options.status_height + this.options.debug_height*/);
	}
	draw () {
		this.sketch.background(240);
		this.drawTopScoreBar();

		if (this.gameState.isRunning) {
			this.drawGame();
		} else {
			this.drawWinner();
		}
	}

	drawGame() {
		if (!this.gameState.isFight) {
			if (this.obstacles.length < this.obstacles_max) {
				let r = Math.random();
				if (r < this.obstacles_chances) {
					this.obstacles.push(new CatObstacle(this.sketch, this.options.board_width, this.options.board_height, "/pogo-avatar.png"));
				}
			}
		}

		for(var obj of this.obstacles) {
			obj.show();
			obj.move();

			if (this.shera.hits(obj)) {
				this.catFight(obj);
			}
		}
		if (this.gameState.isEndGame()) {
			this.endGame();
		}

		let totalObstacles = this.obstacles.length;
		this.obstacles = this.obstacles.filter(i=>!i.readyToRemove())
		let diffObstacles = totalObstacles - this.obstacles.length;
		this.gameState.currentJumps += diffObstacles;

		this.shera.show(this.gameState.isFight);
		this.shera.move();
	}
	keyPressed() {
		if (this.sketch.key == " ") {
			this.shera.jump();
		}
	}
	mousePressed () {
		this.shera.jump();
	}


	_drawBar (yStart) {
		// draw bar background
		this.sketch.fill(this.styleOptions.bar_bgcolor);
		this.sketch.rect(0, yStart + 0, this.options.board_width, this.options.score_height);
		this.sketch.noFill();
	}
	drawTopScoreBar () {
		let scoreMessages = this.gameState.getScoreMessages();

		// starting y position
		let yStart = 0;

		// draw bar background
		this._drawBar(yStart);

		// display score
		this.sketch.textSize(14);
		this.sketch.textAlign(this.sketch.LEFT, this.sketch.TOP)

		this.sketch.fill(this.styleOptions.bar_txtcolor);
		let x = 10;
		let y = yStart + 8;
		this.sketch.text(scoreMessages[0], x, y, this.options.board_width / 2, this.options.score_height); // Text wraps within text box
		this.sketch.text(scoreMessages[1], x + this.options.board_width / 2, y, this.options.board_width / 2, this.options.score_height); // Text wraps within text box
		this.sketch.noFill();

	}

	drawWinner () {
		let message = this.gameState.getWinnerMessage();

		// display score
		this.sketch.fill(this.styleOptions.main_color);
		this.sketch.textSize(100);
		this.sketch.textAlign(this.sketch.CENTER, this.sketch.CENTER)
		this.sketch.text(message, 0, 0, this.options.board_width, this.options.board_height); // Text wraps within text box
		this.sketch.noFill();

		if (this.gameState.isPogoWinner()) {
			this.winnerPogo.show();
		} else {
			this.winnerShera.show();
		}
	}
}

class CatGameCanvas extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.boardgame = null;
		this.options = { board_width: 800, board_height: 400, score_height: 25, status_height: 25, debug_height: 0}
	}

	Sketch = (sketch) => {
		this.boardgame = new GameSketch(sketch, this.options)
	}

	componentDidMount() {
		this.myP5 = new p5(this.Sketch, this.myRef.current)
	}
	componentDidUpdate() {
	}

	render() {
		return (
			<>
				<div ref={this.myRef}></div>
			</>
		)
	}
}


export default CatGameCanvas;

