import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import CatGame from "../../components/CatGame"
import Gist from '../../components/gistWrapper'

export default function Blog({data}) {
	const sourcesShera1 = [
		data.mobileImageShera1.childImageSharp.fluid,
		{
			...data.desktopImageShera1.childImageSharp.fluid,
			media: `(min-width:768px}`
		}
	]

	const sourcesPogo1 = [
		data.mobileImagePogo1.childImageSharp.fluid,
		{
			...data.desktopImagePogo1.childImageSharp.fluid,
			media: `(min-width:768px}`
		}
	]

	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Cat Game</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">Feb 25, 2022</time>
					</div>
				</header>
				<div className="entry-content">
					<p>The cat game using the <a href="https://p5js.org/">P5JS</a> library and following <a href="https://www.youtube.com/watch?v=l0HoJHc-63Q">The Coding Train</a>.</p>
					<div align="center">
						<CatGame />
					</div>


					<div className="cat-game">
						<div className="flex-container">
							<div className="flex-pic">
								<Img fluid={sourcesShera1} alt="Meet Shera" />
							</div>
							<div className="flex-child">
								<h1>Meet Shera</h1>
								<p>
								Shera is a remarkable blind cat who is happy and care free.  She loves to jump around, play hide and seek with her brother Pogo and is a very sneaky cat often getting into places that amazes her care taker.  She loves to climb and her hearing is astonishing.  Nothing can sneak up on her!  She has super long whiskers and uses them to help navigate around new surroundings.
								</p>

								<p>In her household, consent is very important as to not startle her.  Before being picked up, her care taker will say "Touch".  This gives Shera warning that someone is near, and if she gives consent, she'll happily stand by to be picked up.  However, like most cats, she has a mind of her own, and if she doesn't want to be picked up, she dashes off making it impossible for anyone to catch her.  Sometimes, she makes it a game!</p>
							</div>
						</div>

						<div className="flex-container">
							<div className="flex-child">
								<h1>Meet Pogo</h1>
								<p>Pogo is a very loving sighted cat who thinks he's a dog!  He'll come, sit and give high-fives on command, and is super attached to his care taker, often insisting being on her lap 24/7.  Pogo also cares deeply for his sister Shera and has helped her learn the ropes in new places.  When they were kittens, he would play with Shera and showed her how to jump up and down from a chair, a bed, and even the bathroom tub!  Truly a seeing-eye cat for Shera!</p>
							</div>
							<div className="flex-pic">
								<Img fluid={sourcesPogo1} alt="Meet Pogo" />
							</div>
						</div>
					</div>


					{/* <p>The gist below shows you the javascript classes using two main class types.</p>
					<p>BoardGame describes the rules of the game, but does not contain any P5 library specific drawing features.</p>
					<p>BoardGameSketch renders the board with all the current pieces using the P5 library drawing features.</p>
					<p><Gist id='552d42eeda874f71d2b786e4e8b9b208' /></p> */}
				</div>
			</article>
		</Layout>
	)
}



export const query = graphql`
query {
	mobileImageShera1: file(relativePath: { eq: "blogs/cats-game/shera-avatar-large.png" }) {
		childImageSharp {
			fluid(maxWidth: 100, quality: 93) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
	desktopImageShera1: file(
		relativePath: { eq: "blogs/cats-game/shera-avatar-large.png" }
	) {
		childImageSharp {
			fluid(maxWidth: 300, quality: 93) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}

	mobileImagePogo1: file(relativePath: { eq: "blogs/cats-game/pogo-avatar-large.png" }) {
		childImageSharp {
			fluid(maxWidth: 100, quality: 93) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
	desktopImagePogo1: file(
		relativePath: { eq: "blogs/cats-game/pogo-avatar-large.png" }
	) {
		childImageSharp {
			fluid(maxWidth: 200, quality: 93) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
}`
